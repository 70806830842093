import React from 'react';
import { Route as RouterDomRoute, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthSelectors } from '../redux/ducks/AuthRedux';

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
    const token = useSelector(AuthSelectors.selectToken);

    return (
        <RouterDomRoute
            {...rest}
            render={({ location }) => {
                return isPrivate === !!token ? (
                    <Component location />
                ) : (
                    <Redirect
                        to={{
                            pathname: isPrivate ? '/' : '/home',
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};

export default Route;
