import React, { useState, useEffect, useCallback } from 'react';
import { Popover } from 'antd';
import { format } from 'date-fns';

import * as S from './styles';
import Api from '../../services/Api';
import DatePicker from '../../components/DatePicker';

const api = Api.create();

const Dashboard = ({ company, token }) => {
    const [answersData, setAnswers] = useState([]);
    const [startDate, setStartDate] = useState(new Date('01/01/2020'));
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [mouse, setMouse] = useState(false);

    const fetchAnswers = useCallback(async () => {
        try {
            const { data } = await api.getAnswersData(company, token);
            setAnswers(data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }, [token, company]);

    useEffect(() => {
        fetchAnswers();
    }, [fetchAnswers]);

    const filterAnswers = useCallback(async () => {
        const formatedStart = format(startDate, 'yyyy-MM-dd');
        const formatedEnd = format(endDate, 'yyyy-MM-dd');
        try {
            const { data } = await api.getAnswersData(company, token, formatedStart, formatedEnd);
            setAnswers(data);
        } catch (e) {
            console.log(e);
        }
    }, [token, company, startDate, endDate]);

    useEffect(() => {
        fetchAnswers();
    }, [fetchAnswers]);

    if (!company)
        return (
            <S.Container>
                <h1 style={{ color: '#2173ef', marginLeft: 30, marginTop: 20 }}>
                    Escolha um formulário para ver as informações
                </h1>
            </S.Container>
        );

    if (loading)
        return (
            <S.Container>
                <h1 style={{ color: '#2173ef', marginLeft: 30 }}>Carregando...</h1>
            </S.Container>
        );

    return (
        <S.Container>
            <S.Grid>
                <S.InputsContainer>
                    <div style={{ marginLeft: 12 }}>
                        <DatePicker
                            label="Data de início"
                            selectedDate={startDate}
                            setSelectedDate={setStartDate}
                        />
                    </div>
                    <div style={{ marginLeft: 20 }}>
                        <DatePicker
                            label="Data de fim"
                            selectedDate={endDate}
                            setSelectedDate={setEndDate}
                        />
                    </div>
                    <S.Button
                        onClick={filterAnswers}
                        onMouseDown={() => setMouse(true)}
                        onMouseUp={() => setMouse(false)}
                        press={mouse}
                    >
                        <span>Filtrar</span>
                    </S.Button>
                </S.InputsContainer>
                <S.GridTable>
                    {answersData.map(item => (
                        <S.Tile key={item.question}>
                            <S.GridHeader>
                                <S.QuestionTitle>{item.question}</S.QuestionTitle>
                                <S.QuestionAverage
                                    style={{
                                        color:
                                            item.type === 'nps'
                                                ? item.average < 0
                                                    ? '#E13224'
                                                    : item.average < 50
                                                    ? '#ff7f00'
                                                    : item.average < 75
                                                    ? '#f4ee2c'
                                                    : '#4dc142'
                                                : item.average < 3
                                                ? '#E13224'
                                                : item.average <= 4
                                                ? '#f4ee2c'
                                                : '#4dc142',
                                    }}
                                >
                                    {typeof item.answer[0] === 'number'
                                        ? (Math.round(item.average * 10) / 10).toFixed(1)
                                        : ''}
                                </S.QuestionAverage>
                            </S.GridHeader>
                            <S.AnswersList>
                                {item.answer.map((answer, index) => {
                                    return (
                                        <Popover
                                            content={
                                                <div style={{ maxWidth: 250 }}>
                                                    <span>{answer}</span>
                                                </div>
                                            }
                                        >
                                            <S.Answer key={`${answer}${index}`}>
                                                <span>{answer}</span>
                                            </S.Answer>
                                        </Popover>
                                    );
                                })}
                            </S.AnswersList>
                        </S.Tile>
                    ))}
                </S.GridTable>
            </S.Grid>
        </S.Container>
    );
};

export default Dashboard;
