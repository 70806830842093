import { call, put } from "redux-saga/effects";
import { path } from "ramda";
import { PrizeActions } from "../ducks/PrizeRedux";

export function* prizes(api, { company_slug }) {
  try {
    const response = yield call(api.prizes, company_slug);
    if (response.problem) throw new Error(response.problem);
    const prizes = path(["data"], response);
    yield put(PrizeActions.prizesSuccess(prizes));
  } catch (e) {
    yield put(PrizeActions.prizesFail(e));
  }
}

export function* save_prize(
  api,
  { company_slug, name, start, end, success, fail }
) {
  try {
    const response = yield call(api.save_prize, company_slug, name, start, end);
    if (response.problem) throw new Error(response.problem);
    yield put(PrizeActions.prizes(company_slug));
    success();
  } catch (e) {
    fail("Erro ao salvar prêmio");
  }
}

export function* draw(api, { company_slug, id, success, fail }) {
  try {
    const response = yield call(api.draw, company_slug, id);
    if (response.problem) throw new Error(response.problem);
    const prizes = path(["data"], response);
    yield put(PrizeActions.prizesSuccess(prizes));
    success(prizes);
  } catch (e) {
    fail(e);
  }
}
