import styled from 'styled-components';
import { Form as AntForm, Input as AntInput } from 'antd';

export const Form = styled(AntForm)``;

export const FormItem = styled(AntForm.Item)`
    margin-bottom: 10px;

    .ant-form-item-control-wrapper {
        .ant-form-item-control {
            .ant-form-explain {
                font-family: Gilroy-Medium;
            }
        }
    }
`;

export const Input = styled(AntInput)`
    font-family: Gilroy-Medium;
    padding-top: 3px;
`;
