import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'antd';
import Lottie from 'react-lottie';
import animationData from '../../lottie/animation.json';

import Api from '../../services/Api';
import { Form, FormItem, Input } from '../../components/Form';
import { PrimaryButton } from '../../components/Button';
import * as S from './styles';
import { AuthActions } from '../../redux/ducks/AuthRedux';

const api = Api.create();

const Login = () => {
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassowrd] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        try {
            const { data } = await api.auth(user, password);
            dispatch(AuthActions.auth(data.token));
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert('Ocorreru um erro na autenticação');
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <S.LoginWrapper>
            <S.LogoImage />
            <Form onSubmit={handleSubmit} style={{ minWidth: 300 }}>
                <FormItem>
                    <Input
                        value={user}
                        onChange={e => setUser(e.target.value)}
                        size="large"
                        prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="E-mail"
                    />
                </FormItem>
                <FormItem>
                    <Input
                        value={password}
                        onChange={e => setPassowrd(e.target.value)}
                        size="large"
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Senha"
                    />
                </FormItem>

                <PrimaryButton type="primary" htmlType="submit">
                    Entrar
                </PrimaryButton>
            </Form>
            <div style={{ marginTop: 30, height: 80 }}>
                {loading && <Lottie options={defaultOptions} height={80} width={80} />}
            </div>
        </S.LoginWrapper>
    );
};

const WrappedLogin = Form.create({ name: 'login' })(Login);
export default WrappedLogin;
