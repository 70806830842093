import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';
import { Colors } from '../../themes';

export const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 ${Colors.border};

    span {
        font-family: Gilroy-Medium;
    }
`;

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0;
    width: 220px;
`;
export const LogoImage = styled.img`
    width: 170px;
    height: 28px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex: 1;
    padding-right: 40px;
    align-items: center;
    justify-content: flex-end;
`;

export const EyeButton = styled.div`
    display: flex;
    cursor: pointer;
    height: 40px;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    svg {
        margin-right: 10px;
        color: #2173ef;
        transition: color 0.3s;
    }

    span {
        color: #2173ef;
        font-size: 18px;
        transition: color 0.3s;
    }

    &:hover {
        svg {
            color: ${shade(0.2, '#2173ef')};
        }

        span {
            color: ${shade(0.2, '#2173ef')};
        }
    }
`;

export const Button = styled.div`
    display: flex;
    cursor: pointer;
    height: 40px;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #2173ef;
    transition: all 0.2s ease;

    &:hover {
        background-color: ${shade(0.2, '#2173ef')};
    }

    ${props =>
        props.press &&
        css`
            transform: scale(0.95);
        `}

    span {
        color: #fff;
        font-size: 18px;
    }
`;

const spin = keyframes`
     0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loading = styled.div`
    border: 4px solid #2173ef;
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: ${spin} 0.8s linear infinite;
    align-self: center;

    ${props =>
        props.companies &&
        css`
            margin-top: 18px;
        `}
`;

export const Dropdown = styled.div`
    position: relative;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    padding: 10px;

    .title {
        font-size: 18px;
        color: #2173ef;
        transition: color 0.3s;
    }

    .dropdown-content {
        display: flex;
        flex-direction: column;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        background-color: #f9f9f9;
        min-height: 60px;
        min-width: 182px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 5;
        margin-top: 10px;
        right: 0;
        left: -6px;
        transition: all 0.3s ease;
    }

    &:hover .title {
        color: ${shade(0.2, '#2173ef')};
    }

    &:hover .dropdown-content {
        visibility: visible;
        opacity: 1;
    }
`;

export const NameDiv = styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props =>
        props.selected &&
        css`
            background-color: #2173ef;
        `}

    .name {
        font-size: 18px;
        color: #2173ef;
        transition: color 0.3s ease;
        text-transform: capitalize;
        text-align: center;

        ${props =>
            props.selected &&
            css`
                color: #fff;
            `}
    }

    &:hover .name {
        color: ${shade(0.4, '#2173ef')};
        ${props =>
            props.selected &&
            css`
                color: #fff;
            `}
    }
`;
