import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { animated } from 'react-spring';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 20%;
    max-width: 82%;

    .close {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding: 4px;
        margin-right: 12px;
        margin-bottom: 12px;

        svg {
            color: white;
        }
    }
`;

export const TextContainer = styled(animated.div)`
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    border: solid 2px #fff;
    margin: 0 12px 12px 0;
    max-width: 85%;
    cursor: ${props => (props.selected ? 'pointer' : 'default')};

    ${props =>
        props.cur &&
        css`
            border: solid 2px #2173ef;
        `}

    .text {
        overflow-wrap: break-word;
        max-width: 85%;
    }

    .time {
        color: #a2a8b0;
        align-self: flex-end;
        justify-self: flex-end;
        margin-left: 8px;
        font-size: 12px;
    }
`;

export const ButtonsContainer = styled.div`
    margin-top: 8px;
    margin-bottom: 20px;
    display: flex;
    align-self: flex-end;
    max-width: 100%;
`;

export const Button = styled.div`
    display: flex;
    height: 40px;
    padding: 0 16px;
    background-color: #fff;
    margin-right: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.4s;

    &:hover {
        background-color: ${shade(0.1, '#fff')};
    }

    span {
        color: #2173ef;
        font-size: 16px;
    }

    ${props =>
        props.selected &&
        css`
            background-color: #2173ef;

            &:hover {
                background-color: ${shade(0.2, '#2173ef')};
            }

            span {
                color: #fff;
            }
        `}
`;
