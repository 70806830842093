import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import Routes from './routes';
import 'antd/dist/antd.css';
import GlobalStyle from './themes/global-styles';

function App() {
    return (
        <DndProvider backend={HTML5Backend}>
            <Routes />
            <GlobalStyle />
        </DndProvider>
    );
}

export default App;
