import { createGlobalStyle } from 'styled-components';
import { generateMedia } from 'styled-media-query';

import GilroyBold from './fonts/Gilroy-Bold.ttf';
import GilroyHeavy from './fonts/Gilroy-Heavy.ttf';
import GilroyLight from './fonts/Gilroy-Light.ttf';
import GilroyMedium from './fonts/Gilroy-Medium.ttf';
import GilroyRegular from './fonts/Gilroy-Regular.ttf';

const GlobalStyle = createGlobalStyle`


    @font-face {
        font-family: 'Gilroy-Bold';
        src: url(${GilroyBold}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Gilroy-Heavy';
        src: url(${GilroyHeavy})  format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Gilroy-Light';
        src: url(${GilroyLight})  format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gilroy-Medium';
        src: url(${GilroyMedium})  format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Gilroy-Regular';
        src: url(${GilroyRegular})  format('truetype');
        font-weight: normal;
        font-style: normal;
    }

  html,
  body,
  #root {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  * {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    outline: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased !important;
    margin: 0;
    padding: 0;
  }

  body.fontLoaded {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }
`;

export default GlobalStyle;

export const customMedia = generateMedia({
    desktop: '78em',
    tablet: '55em',
    mobile: '42em',
    two_grid_size: '68em',
    one_grid_size: '29em',
});
