/* eslint-disable no-multi-assign */
import React, { useState, useEffect } from 'react';
import { FiDownload, FiEye } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import Api from '../../services/Api';
import * as S from './styles';
import LogoSvg from '../../images/logo.svg';
import { AuthSelectors } from '../../redux/ducks/AuthRedux';

const api = Api.create();

const Navbar = ({ action, loading, link, company, setCompany }) => {
    const token = useSelector(AuthSelectors.selectToken);

    const [companies, setCompanies] = useState([]);
    const [loadingCompanies, setLoadingCompanies] = useState(true);
    const [mouse, setMouse] = useState(false);

    useEffect(() => {
        api.getUser(token).then(response => {
            const { companies: comps } = response.data;
            setCompanies(comps);
            setLoadingCompanies(false);
        });
    }, [token]);

    return (
        <S.Nav>
            <S.LogoWrapper>
                <S.LogoImage src={LogoSvg} />
            </S.LogoWrapper>
            <S.ButtonsContainer>
                <S.Dropdown>
                    <span className="title">Escolher Formulário</span>
                    <div className="dropdown-content">
                        {loadingCompanies ? (
                            <S.Loading companies />
                        ) : (
                            companies.map((item, index) => {
                                return (
                                    <S.NameDiv
                                        onClick={() => setCompany(item.slug)}
                                        key={index}
                                        className="company-name"
                                        selected={company === item.slug}
                                    >
                                        <span className="name">{item.slug}</span>
                                    </S.NameDiv>
                                );
                            })
                        )}
                    </div>
                </S.Dropdown>
                <S.EyeButton onClick={() => link()}>
                    <FiEye size={20} />
                    <span>Visualizar</span>
                </S.EyeButton>
                <S.EyeButton
                    onClick={async () => {
                        if (!company) return alert('Escolha uma empresa primeiro!');
                        const { data } = await api.download(company, 'salao', token);
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const s = document.createElement('a');
                        s.href = url;
                        s.setAttribute('download', 'file.csv');
                        document.body.appendChild(s);
                        s.click();
                        return data;
                    }}
                >
                    <FiDownload size={20} />
                    <span>Baixar Arquivo</span>
                </S.EyeButton>
                <S.Button
                    onMouseDown={() => setMouse(true)}
                    onMouseUp={() => setMouse(false)}
                    onClick={() => {
                        if (!company) return alert('Escolha uma empresa primeiro!');
                        if (loading) return null;
                        return action();
                    }}
                    press={mouse}
                >
                    {loading ? <S.Loading /> : <span>Postar chatbot</span>}
                </S.Button>
            </S.ButtonsContainer>
        </S.Nav>
    );
};

export default Navbar;
