import Colors from './Colors';
import { generateMedia } from "styled-media-query";

const customMedia = generateMedia({
  desktop: "78em",
  tablet: "55em",
  mobile: "42em"
});

export { Colors, customMedia };
