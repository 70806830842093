import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

/* Types & Action Creators */

const { Types, Creators } = createActions({
    auth: ['token'],
    logout: null,
});

export const AuthTypes = Types;
export const AuthActions = Creators;

/* Selectors */

const selectToken = createSelector(
    state => state.auth,
    auth => auth.token,
);

export const AuthSelectors = {
    selectToken,
};

/* Initial State */

export const INITIAL_STATE = Immutable({
    token: null,
});

/* Reducers */

export const auth = (state, { token }) => state.merge({ token });

export const logout = () => INITIAL_STATE;

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
    [Types.AUTH]: auth,
    [Types.LOGOUT]: logout,
});
