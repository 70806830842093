import React, { useState, useCallback } from 'react';
import { FiSend, FiX, FiUser, FiRotateCcw, FiStar } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import _ from 'lodash';

import * as S from './styles';
import Api from '../../services/Api';
import { AuthSelectors } from '../../redux/ducks/AuthRedux';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import ChatBubble from '../../components/ChatBubble';
import arrow from '../../images/arrow.svg';
import Respostas from '../Respostas';
import Dashboard from '../Dashboard';

const api = Api.create();

const HomePage = () => {
    const [active, setActive] = useState('home');
    const [company, setCompany] = useState('');
    const [text, setText] = useState('');
    const [chat, setChat] = useState([]);
    const [bad, setBad] = useState({ expanded: false });
    const [medium, setMedium] = useState({ expanded: false });
    const [good, setGood] = useState({ expanded: false });
    const [badStar, setBadStar] = useState({ expanded: false });
    const [mediumStar, setMediumStar] = useState({ expanded: false });
    const [goodStar, setGoodStar] = useState({ expanded: false });
    const [nps, setNps] = useState(false);
    const [hasNps, setHasNps] = useState(false);
    const [hasText, setHasText] = useState(false);
    const [hasScore, setHasScore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idx, setIdx] = useState(null);
    const token = useSelector(AuthSelectors.selectToken);

    const array1 = [0, 1, 2, 3, 4, 5, 6];
    const array2 = [7, 8];
    const array3 = [9, 10];

    const height1 = bad.expanded ? '180px' : '66px';
    const height2 = medium.expanded ? '180px' : '66px';
    const height3 = good.expanded ? '180px' : '66px';

    const heightStar1 = badStar.expanded ? '180px' : '66px';
    const heightStar2 = mediumStar.expanded ? '180px' : '66px';
    const heightStar3 = goodStar.expanded ? '180px' : '66px';

    const clear = useCallback(() => {
        setNps(false);
        setChat([]);
        setIdx(null);
        setHasText(false);
        setHasNps(false);
        setHasScore(false);
        setBad({ txt: '', expanded: false });
        setMedium({ txt: '', expanded: false });
        setGood({ txt: '', expanded: false });
        setBadStar({ expanded: false });
        setMediumStar({ expanded: false });
        setGoodStar({ expanded: false });
    }, []);

    const handleMessages = () => {
        if (text) {
            const newChat = [...chat];
            const newTime = format(new Date(), 'HH:mm');
            newChat.push({
                text,
                time: newTime,
                answer: '',
                type: !hasNps && !hasText && !hasScore ? 'none' : 'end',
            });
            setChat(newChat);
            setText('');
        }
    };

    const postChatSingle = async () => {
        if (!hasNps && !hasText && !hasScore) return;
        setLoading(true);
        const newChat = [...chat];
        const filteredChat = newChat.filter(item => item.type === 'none');
        // console.log(filteredChat, 'filtered');
        // const { text: messages } =
        //     filteredChat.length > 0
        //         ? filteredChat.reduce((prevItem, item) => {
        //               prevItem.text += `\n${item.text}`;
        //               return prevItem;
        //           })
        //         : '';
        let obj2 = {};
        const x = newChat.map((item, index) => {
            if (index < filteredChat.length) return null;
            if (index === filteredChat.length) {
                const obj1 = {
                    identifier: 'WELCOME',
                    messages: `${filteredChat[0].text}\n${item.text}`,
                    answer_type:
                        item.type === 'nps' ? 'INTEGER' : item.type === 'text' ? 'TEXT' : 'SCORE',
                    answer_parameter_name:
                        item.type === 'nps'
                            ? 'NPS'
                            : item.type === 'text'
                            ? 'TEXT'
                            : `name${index}`,
                    selectors:
                        item.type === 'nps'
                            ? [
                                  {
                                      selector: 3,
                                      value: '0',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '1',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '2',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '3',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '4',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '5',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '6',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '7',
                                      child: `NPS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '8',
                                      child: `NPS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '9',
                                      child: `NPS_GOOD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '10',
                                      child: `NPS_GOOD${index}`,
                                  },
                              ]
                            : item.type === 'score'
                            ? [
                                  {
                                      selector: 3,
                                      value: '1',
                                      child: `STARS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '2',
                                      child: `STARS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '3',
                                      child: `STARS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '4',
                                      child: `STARS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '5',
                                      child: `STARS_GOOD${index}`,
                                  },
                              ]
                            : [
                                  {
                                      selector: 9,
                                      value: item.type === 'text' ? '' : '1',
                                      child: `chat${index + 1}`,
                                  },
                              ],
                };
                obj2 = { ...obj1 };
                return obj1;
            }
            return null;
        });
        const intents = _.compact(x);
        obj2.identifier = 'WELCOME_WITH_NAME';
        intents.push(obj2);
        const array = newChat.map((item, index) => {
            if (index < filteredChat.length) return null;
            if (item.type === 'nps') {
                let badObj;
                let neutralObj;
                let goodObj;

                if (item.bad && item.bad.includes('?')) {
                    badObj = {
                        identifier: `NPS_BAD${index}`,
                        messages: item.bad,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'NPS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    badObj = {
                        identifier: `NPS_BAD${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? item.bad
                                    ? `${item.bad}\n${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                    : `${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.bad
                                ? `${item.bad}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type: 'INTEGER',
                        answer_parameter_name: `name${index}`,
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end'
                                ? undefined
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }
                if (item.medium && item.medium.includes('?')) {
                    neutralObj = {
                        identifier: `NPS_NEUTRAL${index}`,
                        messages: item.medium,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'NPS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    neutralObj = {
                        identifier: `NPS_NEUTRAL${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? item.medium
                                    ? `${item.medium}\n${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                    : `${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.medium
                                ? `${item.medium}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type: 'INTEGER',
                        answer_parameter_name: `name${index}`,
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end'
                                ? undefined
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }
                if (item.good && item.good.includes('?')) {
                    goodObj = {
                        identifier: `NPS_GOOD${index}`,
                        messages: item.good,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'NPS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    goodObj = {
                        identifier: `NPS_GOOD${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? item.good
                                    ? `${item.good}\n${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                    : `${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.good
                                ? `${item.good}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type: 'INTEGER',
                        answer_parameter_name: `name${index}`,
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end'
                                ? undefined
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }
                intents.push(badObj, neutralObj, goodObj);
            }
            if (item.type === 'score') {
                let badObj;
                let neutralObj;
                let goodObj;

                if (item.bad && item.bad.includes('?')) {
                    badObj = {
                        identifier: `STARS_BAD${index}`,
                        messages: item.bad,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    badObj = {
                        identifier: `STARS_BAD${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? item.bad
                                    ? `${item.bad}\n${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                    : `${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.bad
                                ? `${item.bad}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type: 'SCORE',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end'
                                ? undefined
                                : [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ],
                    };
                }
                if (item.medium && item.medium.includes('?')) {
                    neutralObj = {
                        identifier: `STARS_NEUTRAL${index}`,
                        messages: item.medium,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    neutralObj = {
                        identifier: `STARS_NEUTRAL${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? item.medium
                                    ? `${item.medium}\n${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                    : `${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.medium
                                ? `${item.medium}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type: 'SCORE',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end'
                                ? undefined
                                : [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ],
                    };
                }
                if (item.good && item.good.includes('?')) {
                    goodObj = {
                        identifier: `STARS_GOOD${index}`,
                        messages: item.good,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    goodObj = {
                        identifier: `STARS_GOOD${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? item.good
                                    ? `${item.good}\n${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                    : `${
                                          newChat[index + 1].text
                                      }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.good
                                ? `${item.good}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type: 'SCORE',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end'
                                ? undefined
                                : [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ],
                    };
                }
                intents.push(badObj, neutralObj, goodObj);
            }
            if (filteredChat.length === 1 && !hasNps && !hasScore && chat.length === 2) {
                const obj = {
                    identifier: `chat${index + 1}`,
                    messages: `${item.answer}\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`,
                    end_conversation: true,
                };
                return obj;
            }
            if (item.answer) {
                const objp = {
                    identifier: `chat${index}`,
                    messages: item.text,
                    answer_type: 'TEXT',
                    answer_parameter_name: `name${index}`,
                    selectors: [
                        {
                            selector: 9,
                            value: '1',
                            child: `chat${index + 1}`,
                        },
                    ],
                };
                intents.push(objp);
                const obj = {
                    identifier: `chat${index + 1}`,
                    messages: `${item.answer}\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`,
                    end_conversation: true,
                };
                return obj;
            }

            if (index > filteredChat.length && item.type !== 'end') {
                const obj = {
                    identifier: `chat${index}`,
                    messages: item.text,
                    answer_type:
                        item.type === 'text' ? 'TEXT' : item.type === 'nps' ? 'INTEGER' : 'SCORE',
                    answer_parameter_name: `name${index}`,
                    selectors:
                        item.type === 'text'
                            ? [
                                  {
                                      selector: 9,
                                      value: '1',
                                      child: `chat${index + 1}`,
                                  },
                              ]
                            : item.type === 'nps'
                            ? [
                                  {
                                      selector: 3,
                                      value: '0',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '1',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '2',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '3',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '4',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '5',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '6',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '7',
                                      child: `NPS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '8',
                                      child: `NPS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '9',
                                      child: `NPS_GOOD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '10',
                                      child: `NPS_GOOD${index}`,
                                  },
                              ]
                            : [
                                  { selector: 3, value: '1', child: `STARS_BAD${index}` },
                                  { selector: 3, value: '2', child: `STARS_BAD${index}` },
                                  { selector: 3, value: '3', child: `STARS_NEUTRAL${index}` },
                                  { selector: 3, value: '4', child: `STARS_NEUTRAL${index}` },
                                  { selector: 3, value: '5', child: `STARS_GOOD${index}` },
                              ],
                };
                return obj;
            }
            if (index > filteredChat.length && item.type === 'end') {
                const obj = {
                    identifier: `chat${index}`,
                    messages: `${item.text}\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`,
                    end_conversation: true,
                };
                return obj;
            }
            return null;
        });
        const j = _.compact(array);
        intents.push(...j);
        // console.log(intents);
        const newData = {
            intents,
            companies: company.toLowerCase(),
            places: 'salao',
        };

        const response = await api.postChat(newData, token);
        if (response.status <= 204) {
            alert('Chat postado com sucesso!');
        } else {
            alert('Ocorreu um erro inesperado. Tente novamente');
        }
        setLoading(false);
    };

    const postChatMixed = async () => {
        if (!hasNps && !hasText && !hasScore) return;
        setLoading(true);
        const newChat = [...chat];
        const filteredChat = newChat.filter(item => item.type === 'none');
        // console.log(filteredChat, 'filtered');
        // const { text: messages } =
        //     filteredChat.length > 0
        //         ? filteredChat.reduce((prevItem, item) => {
        //               prevItem.text += `\n${item.text}`;
        //               return prevItem;
        //           })
        //         : '';
        let obj2 = {};
        const x = newChat.map((item, index) => {
            if (index < filteredChat.length) return null;
            if (index === filteredChat.length) {
                const obj1 = {
                    identifier: 'WELCOME',
                    messages: `${filteredChat[0].text}\n${item.text}`,
                    answer_type:
                        item.type === 'nps' ? 'INTEGER' : item.type === 'text' ? 'TEXT' : 'SCORE',
                    answer_parameter_name:
                        item.type === 'nps'
                            ? 'NPS'
                            : item.type === 'text'
                            ? 'TEXT'
                            : `name${index}`,
                    selectors:
                        item.type === 'nps'
                            ? [
                                  {
                                      selector: 3,
                                      value: '0',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '1',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '2',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '3',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '4',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '5',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '6',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '7',
                                      child: `NPS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '8',
                                      child: `NPS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '9',
                                      child: `NPS_GOOD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '10',
                                      child: `NPS_GOOD${index}`,
                                  },
                              ]
                            : item.type === 'score'
                            ? [
                                  {
                                      selector: 3,
                                      value: '1',
                                      child: `STARS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '2',
                                      child: `STARS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '3',
                                      child: `STARS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '4',
                                      child: `STARS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '5',
                                      child: `STARS_GOOD${index}`,
                                  },
                              ]
                            : [
                                  {
                                      selector: 9,
                                      value: item.type === 'text' ? '' : '1',
                                      child: `chat${index + 1}`,
                                  },
                              ],
                };
                obj2 = { ...obj1 };
                return obj1;
            }
            return null;
        });
        const intents = _.compact(x);
        obj2.identifier = 'WELCOME_WITH_NAME';
        intents.push(obj2);
        const array = newChat.map((item, index) => {
            if (index < filteredChat.length) return null;

            if (filteredChat.length === 1 && !hasNps && !hasScore) {
                const objp = {
                    identifier: `chat${index + 1}`,
                    messages: item.text,
                    answer_type: item.type === 'text' ? 'TEXT' : 'SCORE',
                    answer_parameter_name: `text${index}`,
                    selectors: [
                        {
                            selector: 9,
                            value: item.type === 'text' ? '' : '1',
                            child: `chat${index + 1}`,
                        },
                    ],
                };
                intents.push(objp);
                const obj = {
                    identifier: `chat${index + 2}`,
                    messages: `${item.answer}\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`,
                    end_conversation: true,
                };
                return obj;
            }

            if (item.type === 'score') {
                let badObj;
                let neutralObj;
                let goodObj;

                if (item.bad && item.bad.includes('?')) {
                    badObj = {
                        identifier: `STARS_BAD${index}`,
                        messages: item.bad,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    badObj = {
                        identifier: `STARS_BAD${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? `${
                                      newChat[index + 1].text
                                  }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.bad
                                ? `${item.bad}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type:
                            newChat[index + 1].type === 'nps'
                                ? 'INTEGER'
                                : newChat[index + 1].type === 'score'
                                ? 'SCORE'
                                : 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end' || newChat[index + 1].type === 'text'
                                ? [
                                      {
                                          selector: 9,
                                          value: '',
                                          child:
                                              newChat[index + 1].type === 'text'
                                                  ? `chat${index + 2}`
                                                  : `chat${index + 1}`,
                                      },
                                  ]
                                : newChat[index + 1].type === 'score'
                                ? [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ]
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }
                if (item.medium && item.medium.includes('?')) {
                    neutralObj = {
                        identifier: `STARS_NEUTRAL${index}`,
                        messages: item.medium,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    neutralObj = {
                        identifier: `STARS_NEUTRAL${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? `${
                                      newChat[index + 1].text
                                  }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.medium
                                ? `${item.medium}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type:
                            newChat[index + 1].type === 'nps'
                                ? 'INTEGER'
                                : newChat[index + 1].type === 'score'
                                ? 'SCORE'
                                : 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end' || newChat[index + 1].type === 'text'
                                ? [
                                      {
                                          selector: 9,
                                          value: '',
                                          child:
                                              newChat[index + 1].type === 'text'
                                                  ? `chat${index + 2}`
                                                  : `chat${index + 1}`,
                                      },
                                  ]
                                : newChat[index + 1].type === 'score'
                                ? [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ]
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }
                if (item.good && item.good.includes('?')) {
                    goodObj = {
                        identifier: `STARS_GOOD${index}`,
                        messages: item.good,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    goodObj = {
                        identifier: `STARS_GOOD${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? `${
                                      newChat[index + 1].text
                                  }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.good
                                ? `${item.good}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type:
                            newChat[index + 1].type === 'nps'
                                ? 'INTEGER'
                                : newChat[index + 1].type === 'score'
                                ? 'SCORE'
                                : 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end' || newChat[index + 1].type === 'text'
                                ? [
                                      {
                                          selector: 9,
                                          value: '',
                                          child:
                                              newChat[index + 1].type === 'text'
                                                  ? `chat${index + 2}`
                                                  : `chat${index + 1}`,
                                      },
                                  ]
                                : newChat[index + 1].type === 'score'
                                ? [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ]
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }
                intents.push(badObj, neutralObj, goodObj);
            }
            if (item.type === 'nps') {
                let badObj;
                let neutralObj;
                let goodObj;

                if (item.bad && item.bad.includes('?')) {
                    badObj = {
                        identifier: `NPS_BAD${index}`,
                        messages: item.bad,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'NPS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    badObj = {
                        identifier: `NPS_BAD${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? `${
                                      newChat[index + 1].text
                                  }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.bad
                                ? `${item.bad}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type:
                            newChat[index + 1].type === 'nps'
                                ? 'INTEGER'
                                : newChat[index + 1].type === 'score'
                                ? 'SCORE'
                                : 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end' || newChat[index + 1].type === 'text'
                                ? [
                                      {
                                          selector: 9,
                                          value: '',
                                          child:
                                              newChat[index + 1].type === 'text'
                                                  ? `chat${index + 2}`
                                                  : `chat${index + 1}`,
                                      },
                                  ]
                                : newChat[index + 1].type === 'score'
                                ? [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ]
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }
                if (item.medium && item.medium.includes('?')) {
                    neutralObj = {
                        identifier: `NPS_NEUTRAL${index}`,
                        messages: item.medium,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'NPS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    neutralObj = {
                        identifier: `NPS_NEUTRAL${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? `${
                                      newChat[index + 1].text
                                  }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.medium
                                ? `${item.medium}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type:
                            newChat[index + 1].type === 'nps'
                                ? 'INTEGER'
                                : newChat[index + 1].type === 'score'
                                ? 'SCORE'
                                : 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end' || newChat[index + 1].type === 'text'
                                ? [
                                      {
                                          selector: 9,
                                          value: '',
                                          child:
                                              newChat[index + 1].type === 'text'
                                                  ? `chat${index + 2}`
                                                  : `chat${index + 1}`,
                                      },
                                  ]
                                : newChat[index + 1].type === 'score'
                                ? [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ]
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }
                if (item.good && item.good.includes('?')) {
                    goodObj = {
                        identifier: `NPS_GOOD${index}`,
                        messages: item.good,
                        answer_type: 'TEXT',
                        answer_parameter_name: 'NPS_TEXT',
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                } else {
                    goodObj = {
                        identifier: `NPS_GOOD${index}`,
                        messages:
                            newChat[index + 1].type === 'end'
                                ? `${
                                      newChat[index + 1].text
                                  }\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`
                                : item.good
                                ? `${item.good}\n${newChat[index + 1].text}`
                                : newChat[index + 1].text,
                        answer_type:
                            newChat[index + 1].type === 'nps'
                                ? 'INTEGER'
                                : newChat[index + 1].type === 'score'
                                ? 'SCORE'
                                : 'TEXT',
                        answer_parameter_name: 'STARS_TEXT',
                        end_conversation: newChat[index + 1].type === 'end',
                        selectors:
                            newChat[index + 1].type === 'end' || newChat[index + 1].type === 'text'
                                ? [
                                      {
                                          selector: 9,
                                          value: '',
                                          child:
                                              newChat[index + 1].type === 'text'
                                                  ? `chat${index + 2}`
                                                  : `chat${index + 1}`,
                                      },
                                  ]
                                : newChat[index + 1].type === 'score'
                                ? [
                                      { selector: 3, value: '1', child: `STARS_BAD${index + 1}` },
                                      { selector: 3, value: '2', child: `STARS_BAD${index + 1}` },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `STARS_NEUTRAL${index + 1}`,
                                      },
                                      { selector: 3, value: '5', child: `STARS_GOOD${index + 1}` },
                                  ]
                                : [
                                      {
                                          selector: 3,
                                          value: '0',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '1',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '2',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '3',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '4',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '5',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '6',
                                          child: `NPS_BAD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '7',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '8',
                                          child: `NPS_NEUTRAL${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '9',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                      {
                                          selector: 3,
                                          value: '10',
                                          child: `NPS_GOOD${index + 1}`,
                                      },
                                  ],
                    };
                }

                intents.push(badObj, neutralObj, goodObj);
            }
            if (index > filteredChat.length && item.type !== 'end') {
                const obj = {
                    identifier: `chat${index}`,
                    messages: item.text,
                    answer_type:
                        item.type === 'text' ? 'TEXT' : item.type === 'nps' ? 'INTEGER' : 'SCORE',
                    answer_parameter_name: `name${index}`,
                    selectors:
                        item.type === 'text'
                            ? [
                                  {
                                      selector: 9,
                                      value: '1',
                                      child: `chat${index + 1}`,
                                  },
                              ]
                            : item.type === 'nps'
                            ? [
                                  {
                                      selector: 3,
                                      value: '0',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '1',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '2',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '3',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '4',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '5',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '6',
                                      child: `NPS_BAD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '7',
                                      child: `NPS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '8',
                                      child: `NPS_NEUTRAL${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '9',
                                      child: `NPS_GOOD${index}`,
                                  },
                                  {
                                      selector: 3,
                                      value: '10',
                                      child: `NPS_GOOD${index}`,
                                  },
                              ]
                            : [
                                  { selector: 3, value: '1', child: `STARS_BAD${index}` },
                                  { selector: 3, value: '2', child: `STARS_BAD${index}` },
                                  { selector: 3, value: '3', child: `STARS_NEUTRAL${index}` },
                                  { selector: 3, value: '4', child: `STARS_NEUTRAL${index}` },
                                  { selector: 3, value: '5', child: `STARS_GOOD${index}` },
                              ],
                };
                return obj;
            }
            if (filteredChat.length > index && item.type === 'text') {
                if (item.answer) {
                    const objp = {
                        identifier: `chat${index}`,
                        messages: item.text,
                        answer_type: 'TEXT',
                        answer_parameter_name: `name${index}`,
                        selectors: [
                            {
                                selector: 9,
                                value: '',
                                child: `chat${index + 1}`,
                            },
                        ],
                    };
                    intents.push(objp);
                    const obj = {
                        identifier: `chat${index + 1}`,
                        messages: `${item.answer}\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`,
                        end_conversation: true,
                    };
                    return obj;
                }
                const obj = {
                    identifier: `chat${index}`,
                    messages: item.text,
                    answer_type: 'TEXT',
                    answer_parameter_name: `name${index}`,
                    selectors: [
                        {
                            selector: 9,
                            value: '',
                            child: `chat${index + 1}`,
                        },
                    ],
                };
                return obj;
            }
            if (index > filteredChat.length && item.type === 'end') {
                const obj = {
                    identifier: `chat${index}`,
                    messages: `${item.text}\nNem parece um formulário, né? Melhore sua experiência com o Fale Cliente! Saiba mais aqui: https://falacliente.com`,
                    end_conversation: true,
                };
                return obj;
            }

            return null;
        });
        const j = _.compact(array);
        intents.push(...j);
        // console.log(intents);
        // console.log('mixed');
        const newData = {
            intents,
            companies: company.toLowerCase(),
            places: 'salao',
        };
        const response = await api.postChat(newData, token);
        if (response.status <= 204) {
            alert('Chat postado com sucesso');
        } else {
            alert('Ocorreu um erro inesperado. Tente novamente');
        }
        setLoading(false);
    };

    const handleAction = () => {
        if (hasNps && hasScore && hasText) return postChatMixed();
        if (hasNps && hasScore) return postChatMixed();
        if (hasNps && hasText) return postChatMixed();
        if (hasText && hasScore) return postChatMixed();
        return postChatSingle();
    };

    const renderNps = index => {
        return (
            <div style={{ overflowY: 'auto' }}>
                <S.TitleContainer>
                    <p>NPS</p>
                    <p>Clique nas setas para editar as respostas</p>
                </S.TitleContainer>
                <S.DropBox expanded={bad.expanded} height={height1}>
                    <div style={{ display: 'flex' }}>
                        {array1.map(item => (
                            <S.Red>
                                <span>{item}</span>
                            </S.Red>
                        ))}
                        <div className="fill" />
                        <img
                            src={arrow}
                            alt="oid"
                            onClick={() => setBad({ ...bad, expanded: !bad.expanded })}
                        />
                    </div>
                    <S.TextAreaNPS expanded={bad.expanded} style={{ marginTop: 20 }}>
                        <textarea
                            value={chat[index].bad}
                            onChange={e => {
                                const newChat = [...chat];
                                newChat[index].bad = e.target.value;
                                setChat(newChat);
                            }}
                            cols={56}
                            rows={3}
                            placeholder="Digite sua respota aqui..."
                        />
                    </S.TextAreaNPS>
                </S.DropBox>
                <S.DropBox expanded={medium.expanded} height={height2}>
                    <div style={{ display: 'flex' }}>
                        {array2.map(item => (
                            <S.Yellow>
                                <span>{item}</span>
                            </S.Yellow>
                        ))}
                        <div className="fill" />
                        <img
                            src={arrow}
                            alt="oid"
                            onClick={() => setMedium({ ...medium, expanded: !medium.expanded })}
                        />
                    </div>
                    <S.TextAreaNPS expanded={medium.expanded} style={{ marginTop: 20 }}>
                        <textarea
                            value={chat[index].medium}
                            onChange={e => {
                                const newChat = [...chat];
                                newChat[index].medium = e.target.value;
                                setChat(newChat);
                            }}
                            cols={56}
                            rows={3}
                            placeholder="Digite sua respota aqui..."
                        />
                    </S.TextAreaNPS>
                </S.DropBox>
                <S.DropBox expanded={good.expanded} style={{ marginBottom: 20 }} height={height3}>
                    <div style={{ display: 'flex' }}>
                        {array3.map(item => (
                            <S.Green>
                                <span>{item}</span>
                            </S.Green>
                        ))}
                        <div className="fill" />
                        <img
                            src={arrow}
                            alt="oid"
                            onClick={() => setGood({ ...good, expanded: !good.expanded })}
                        />
                    </div>
                    <S.TextAreaNPS expanded={good.expanded} style={{ marginTop: 20 }}>
                        <textarea
                            value={chat[index].good}
                            onChange={e => {
                                const newChat = [...chat];
                                newChat[index].good = e.target.value;
                                setChat(newChat);
                            }}
                            cols={56}
                            rows={3}
                            placeholder="Digite sua respota aqui..."
                        />
                    </S.TextAreaNPS>
                </S.DropBox>
            </div>
        );
    };

    const renderText = () => {
        return <></>;
    };

    const renderScore = index => {
        return (
            <div style={{ overflowY: 'auto' }}>
                <S.TitleContainer>
                    <p>Respostas</p>
                    <p>Clique nas setas para editar as respostas</p>
                </S.TitleContainer>
                <S.DropBox expanded={badStar.expanded} height={heightStar1}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
                            <FiStar color="#FFD706" size={30} />
                            <div
                                style={{
                                    height: 2,
                                    backgroundColor: 'grey',
                                    width: 30,
                                    marginRight: 12,
                                    marginLeft: 12,
                                }}
                            />
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                        </div>
                        <div className="fill" />
                        <img
                            src={arrow}
                            alt="oid"
                            onClick={() => setBadStar({ ...badStar, expanded: !badStar.expanded })}
                        />
                    </div>
                    <S.TextAreaNPS expanded={badStar.expanded} style={{ marginTop: 20 }}>
                        <textarea
                            value={chat[index].bad}
                            onChange={e => {
                                const newChat = [...chat];
                                newChat[index].bad = e.target.value;
                                setChat(newChat);
                            }}
                            cols={56}
                            rows={3}
                            placeholder="Digite sua respota aqui..."
                        />
                    </S.TextAreaNPS>
                </S.DropBox>
                <S.DropBox expanded={mediumStar.expanded} height={heightStar2}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                            <div
                                style={{
                                    height: 2,
                                    backgroundColor: 'grey',
                                    width: 30,
                                    marginRight: 12,
                                    marginLeft: 12,
                                }}
                            />
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                        </div>
                        <div className="fill" />
                        <img
                            src={arrow}
                            alt="oid"
                            onClick={() =>
                                setMediumStar({ ...mediumStar, expanded: !mediumStar.expanded })
                            }
                        />
                    </div>
                    <S.TextAreaNPS expanded={mediumStar.expanded} style={{ marginTop: 20 }}>
                        <textarea
                            value={chat[index].medium}
                            onChange={e => {
                                const newChat = [...chat];
                                newChat[index].medium = e.target.value;
                                setChat(newChat);
                            }}
                            cols={56}
                            rows={3}
                            placeholder="Digite sua respota aqui..."
                        />
                    </S.TextAreaNPS>
                </S.DropBox>
                <S.DropBox
                    expanded={goodStar.expanded}
                    style={{ marginBottom: 20 }}
                    height={heightStar3}
                >
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginTop: 6 }}>
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                            <FiStar color="#FFD706" size={30} />
                        </div>
                        <div className="fill" />
                        <img
                            src={arrow}
                            alt="oid"
                            onClick={() =>
                                setGoodStar({ ...goodStar, expanded: !goodStar.expanded })
                            }
                        />
                    </div>
                    <S.TextAreaNPS expanded={goodStar.expanded} style={{ marginTop: 20 }}>
                        <textarea
                            value={chat[index].good}
                            onChange={e => {
                                const newChat = [...chat];
                                newChat[index].good = e.target.value;
                                setChat(newChat);
                            }}
                            cols={56}
                            rows={3}
                            placeholder="Digite sua respota aqui..."
                        />
                    </S.TextAreaNPS>
                </S.DropBox>
            </div>
        );
    };

    const copyToClipboard = async () => {
        if (!company) {
            return alert('Escolha uma empresa primeiro!');
        }
        const str = `https://chat.falacliente.com/?company=${company}&place=salao`;
        await navigator.clipboard.writeText(str);
        return alert('Link copiado para à Área de Transferência');
    };

    const renderHome = () => {
        return (
            <div style={{ padding: 30, display: 'flex', width: '100%' }}>
                <S.ChatContainer>
                    <div className="header">
                        <div className="user-container">
                            <div className="user">
                                <FiUser size={24} color="#2173ef" />
                            </div>
                            <span style={{ textTransform: 'capitalize' }}>{company}</span>
                        </div>
                        <div className="clear" onClick={clear}>
                            <FiRotateCcw color="#fff" size={26} />
                        </div>
                    </div>
                    <div className="chat-content">
                        {chat.map((item, index) => {
                            return (
                                <ChatBubble
                                    key={index}
                                    text={item.text}
                                    time={item.time}
                                    nps={type => {
                                        if (!type) return;
                                        setNps(true);
                                        setIdx(index);
                                        setBadStar({ expanded: false });
                                        setMediumStar({ expanded: false });
                                        setGoodStar({ expanded: false });
                                    }}
                                    close={() => {
                                        if (idx === index) {
                                            setNps(false);
                                            setIdx(null);
                                        }
                                        const newChat = [...chat];
                                        newChat[index].type = null;
                                        newChat[index].answer = '';
                                        setChat(newChat);
                                        if (chat[index].type === 'nps') {
                                            setBad({ txt: '', expanded: false });
                                            setMedium({ txt: '', expanded: false });
                                            setGood({ txt: '', expanded: false });
                                        }
                                    }}
                                    current={idx === index}
                                />
                            );
                        })}
                    </div>
                    <div className="write-text">
                        <textarea
                            value={text}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    handleMessages();
                                }
                            }}
                            onChange={e => setText(e.target.value)}
                            cols={50}
                            rows={4}
                            placeholder="Digite seu texto aqui..."
                        />
                        <div onClick={handleMessages} className="icon-container">
                            <FiSend size={24} />
                        </div>
                    </div>
                </S.ChatContainer>
                {nps && (
                    <S.NPSContainer>
                        <S.ContentContainer>
                            <S.ButtonsContainer>
                                <S.Button
                                    onClick={() => {
                                        const newChat = [...chat];
                                        newChat[idx].type = 'nps';
                                        setHasNps(true);
                                        setChat(newChat);
                                    }}
                                    selected={chat[idx].type === 'nps'}
                                    style={{ marginRight: 12 }}
                                >
                                    <span>NPS</span>
                                </S.Button>
                                <S.Button
                                    onClick={() => {
                                        const newChat = [...chat];
                                        newChat[idx].type = 'text';
                                        setHasText(true);
                                        setChat(newChat);
                                    }}
                                    selected={chat[idx].type === 'text'}
                                >
                                    <span>Texto</span>
                                </S.Button>
                                <S.Button
                                    onClick={() => {
                                        const newChat = [...chat];
                                        newChat[idx].type = 'score';
                                        setHasScore(true);
                                        setChat(newChat);
                                    }}
                                    selected={chat[idx].type === 'score'}
                                >
                                    <span>Estrelas</span>
                                </S.Button>
                                <div
                                    className="close"
                                    onClick={() => {
                                        setNps(false);
                                        setIdx(null);
                                    }}
                                >
                                    <FiX size={22} />
                                </div>
                            </S.ButtonsContainer>
                            {chat[idx].type === 'nps'
                                ? renderNps(idx)
                                : chat[idx].type === 'text'
                                ? renderText(idx)
                                : chat[idx].type === 'score'
                                ? renderScore(idx)
                                : null}
                            <div className="fill" />
                        </S.ContentContainer>
                    </S.NPSContainer>
                )}
            </div>
        );
    };

    const renderAnswers = () => <Respostas company={company} token={token} />;

    const renderDashboard = () => <Dashboard company={company} token={token} />;

    const handleContent = () => {
        switch (active) {
            case 'home':
                return renderHome();
            case 'overview':
                return renderAnswers();
            case 'detalhes':
                return renderDashboard();
            default:
                return renderHome();
        }
    };

    return (
        <>
            <Navbar
                loading={loading}
                action={handleAction}
                setCompany={item => setCompany(item)}
                company={company}
                link={copyToClipboard}
            />
            <S.ContainerWrapper>
                <Sidebar active={active} setActive={setActive} />
                <S.ContentWrapper>{handleContent()}</S.ContentWrapper>
            </S.ContainerWrapper>
        </>
    );
};

export default HomePage;
