import styled from 'styled-components';
import LogoSvg from '../../images/logo.svg';

export const LoginWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
`;

export const LogoImage = styled.img`
    content: url(${LogoSvg});
    width: 300px;
    margin-bottom: 30px;
`;
