const colors = {
    border: '#E2E2EA',
    section_title: '#92929D',
    section_option: '#666666',
    background: '#F7F7F7',

    text_bold: '#4A4A4A',

    grey: '#D8D8D8',
    border_grey: '#B9B9B9',
    white: '#fff',
    green: '#47BA81',
    blue: '#00B6E3',
    red: '#F05349',
    dislike_red: '#CB8788',
    yellow: '#FFC54E',
};

export default colors;
