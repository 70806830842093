import styled, { css } from 'styled-components';
import { Colors } from '../../themes';
import { Button as AntButton } from 'antd';

export const LinkButton = styled(AntButton)`
    padding: 0;
    span {
        font-family: Gilroy-Bold !important;
        font-size: 16px;
        color: ${Colors.blue};
        letter-spacing: 0.18px;
    }

    i {
        color: ${Colors.blue};
    }
`;

export const PrimaryButton = styled(AntButton)`
    width: 100%;
    min-height: 40px;
    border-width: 0;
    background-color: ${Colors.blue};

    :hover,
    :focus,
    .active {
        background-color: ${Colors.blue};
        opacity: 0.9;
    }

    span {
        font-family: Gilroy-Bold !important;
        font-size: 16px;
        color: #fff;
        letter-spacing: 0.18px;
    }

    i {
        color: #fff;
    }
`;

export const FormButton = styled(AntButton)`
    width: 100%;
    min-height: 25px;
    border-width: 0;
    border-radius: 10px;
    background-color: ${props => props.color};
    justify-content: flex-start;
    align-items: start;

    :hover,
    :focus,
    .active {
        background-color: ${props => props.color};
        opacity: 0.8;
    }

    span {
        font-family: Gilroy-Bold !important;
        font-size: 16px;
        color: #fff;
        letter-spacing: 0.18px;
    }

    i {
        color: #fff;
    }
`;

export const YellowButton = styled(AntButton)`
    width: 100%;
    height: 22px;
    border-width: 0;
    background-color: ${Colors.yellow};
    flex: 0.5;
    justify-content: center;
    align-items: center;

    :hover,
    :focus,
    .active {
        background-color: ${Colors.yellow};
        opacity: 0.9;
    }

    span {
        font-family: Gilroy-Bold !important;
        font-size: 11px;
        color: #fff;
        letter-spacing: 0.18px;
    }

    i {
        color: #fff;
    }
`;

export const CompartilharButton = styled(AntButton)`
    width: 100%;
    min-height: 40px;
    border-width: 0;
    border-radius: 10px;
    background-color: ${Colors.background};
    margin-top: 10px;

    :hover,
    :focus,
    .active {
        background-color: ${Colors.grey};
        opacity: 0.9;
    }

    span {
        font-family: Gilroy-Bold !important;
        font-size: 16px;
        color: ${Colors.text_bold};
        letter-spacing: 0.18px;
    }

    i {
        color: ${Colors.text_bold};
    }
`;

export const EnviarButton = styled(AntButton)`
    width: 100%;
    min-height: 40px;
    border-width: 2px;
    border-radius: 10px;
    border-color: ${Colors.green};
    background-color: ${Colors.white};

    ${props =>
        props.color &&
        css`
            border-color: ${props => props.color};
        `};

    :hover,
    :focus,
    .active {
        background-color: ${Colors.white};
        border-color: ${Colors.green};
        opacity: 0.9;
        ${props =>
            props.color &&
            css`
                border-color: ${props => props.color};
            `};
    }

    span {
        font-family: Gilroy-Bold !important;
        font-size: 16px;
        color: ${Colors.green};
        letter-spacing: 0.18px;
        ${props =>
            props.color &&
            css`
                color: ${props => props.color};
            `};
    }

    i {
        color: #fff;
    }
`;

export const AbaButton = styled(AntButton)`
    width: 100%;
    min-height: 30px;
    border-width: 0;
    border-radius: 0;
    color: transparent;
    background-color: transparent;
    padding: 0 15px 0 0;

    :hover,
    :focus,
    .active {
        background-color: ${Colors.background};
        opacity: 0.9;
    }

    span {
        font-family: Gilroy-Bold !important;
        font-size: 16px;
        color: ${Colors.section_option};
        letter-spacing: 0.18px;
        text-align: center;
        ${props =>
            props.ativo &&
            css`
                color: ${Colors.blue};
            `};

        :hover,
        :focus,
        .active {
            background-color: ${Colors.background};
            color: ${Colors.section_option};
            opacity: 0.6;
        }
    }

    /*i {
    color: #fff;
  }*/
`;
