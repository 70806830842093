import { combineReducers } from 'redux';
import Auth from './AuthRedux';
import Company from './CompanyRedux';
import Evaluation from './EvaluationRedux';
import Question from './QuestionRedux';
import Prize from './PrizeRedux';

const reducers = combineReducers({
    auth: Auth,
    company: Company,
    evaluation: Evaluation,
    question: Question,
    prize: Prize,
});

export default reducers;
