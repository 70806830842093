import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f7f7f7;
    padding-top: 10px;
    width: 100%;

    span {
        font-family: Gilroy-Bold;
    }
`;

export const Grid = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
`;

export const GridTable = styled.div`
    display: flex;
    min-height: 400px !important;
`;

export const Tile = styled.div`
    margin: 0 12px;
    height: 100%;
    min-width: 300px !important;
`;

export const GridHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-wrap: break-word;
    padding: 28px;
    border-radius: 8px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.14);
    margin-bottom: 20px;
    background-color: #fff;
    height: 100%;
`;

export const AnswersList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 4px;
    margin-bottom: 20px;
`;

export const Answer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 10px 0;
    width: 100%;
    background-color: #fff;
    margin: 10px 0;
    min-height: 60px;
    max-height: 60px;
    font-family: Gilroy-Bold;
    font-size: 18px;
    overflow: hidden;

    span {
        padding: 0 10px;
        max-height: 60px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
    }
`;

export const InputsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 30px 0;
    margin-left: 30px;
`;

export const QuestionTitle = styled.span`
    font-size: 18px;
    align-self: flex-start;
    text-align: left;
`;

export const QuestionAverage = styled.span`
    font-size: 28px;
    margin: 30px 0 20px 0;
`;

export const Button = styled.div`
    display: flex;
    cursor: pointer;
    height: 40px;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #2173ef;
    transition: all 0.2s ease;
    margin-left: 30px

    &:hover {
        background-color: ${shade(0.2, '#2173ef')};
    }

    ${props =>
        props.press &&
        css`
            transform: scale(0.95);
        `}

    span {
        color: #fff;
        font-size: 18px;
    }
`;
