import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { createSelector } from "reselect";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  questions: ["company_slug", "company_type"],
  questionsSuccess: ["questions", "others"],
  questionsFail: ["error"],
  saveQuestions: ["company_slug", "questions"],
  saveQuestionsSuccess: [],
  saveQuestionsFail: ["error"],
  clear: []
});

export const QuestionTypes = Types;
export const QuestionActions = Creators;

/* Selectors */

const selectLoading = createSelector(
  state => state.question.loading,
  loading => loading
);

const selectSaving = createSelector(
  state => state.question.saving,
  saving => saving
);

const selectQuestions = createSelector(
  state => state.question.questions,
  questions => (questions ? questions : [])
);

const selectDefault = createSelector(selectQuestions, questions =>
  questions.filter(q => q.default)
);

const selectNotDefault = createSelector(selectQuestions, questions =>
  questions.filter(q => !q.default)
);

const selectOthers = createSelector(
  state => state.question.others,
  questions => (questions ? questions : [])
);

export const QuestionSelectors = {
  selectLoading,
  selectSaving,
  selectQuestions,
  selectDefault,
  selectNotDefault,
  selectOthers
};

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  saving: false,
  questions: [],
  others: []
});

/* Reducers */

export const request = state => state.merge({ loading: true });
export const success = (state, { questions, others }) =>
  state.merge({ loading: false, questions, others });
export const fail = state => state.merge({ loading: false });

export const saveRequest = state => state.merge({ saving: true });
export const saveSuccess = state => state.merge({ saving: false });
export const saveFail = state => state.merge({ saving: false });

export const clear = state =>
  state.merge({ loading: false, questions: [], others: [] });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.QUESTIONS]: request,
  [Types.QUESTIONS_SUCCESS]: success,
  [Types.QUESTIONS_FAIL]: fail,
  [Types.SAVE_QUESTIONS]: saveRequest,
  [Types.SAVE_QUESTIONS_SUCCESS]: saveSuccess,
  [Types.SAVE_QUESTIONS_FAIL]: saveFail,
  [Types.CLEAR]: clear
});
