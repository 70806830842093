import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { createSelector } from "reselect";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  prizes: ["company_slug"],
  prizesSuccess: ["prizes"],
  prizesFail: ["error"],

  draw: ["company_slug", "id", "success", "fail"],
  savePrize: ["company_slug", "name", "start", "end", "success", "fail"]
});

export const PrizeTypes = Types;
export const PrizeActions = Creators;

/* Selectors */

const selectState = createSelector(
  state => state.prize,
  prize => prize
);

const selectLoading = createSelector(selectState, prize => prize.loading);
const selectPrizes = createSelector(selectState, prize => prize.prizes);

export const PrizeSelectors = {
  selectLoading,
  selectPrizes
};

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  prizes: [],
  error: null
});

/* Reducers */

export const prizes = state => state.merge({ loading: true });

export const prizesSuccess = (state, { prizes }) =>
  state.merge({ loading: false, prizes });

export const prizesFail = (state, { error }) =>
  state.merge({ loading: false, error });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.PRIZES]: prizes,
  [Types.PRIZES_SUCCESS]: prizesSuccess,
  [Types.PRIZES_FAIL]: prizesFail
});
