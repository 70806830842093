import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 30px;
`;

export const Grid = styled.ul`
    display: grid;
    padding: 12px
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    list-style: none;
`;

export const Tile = styled.li`
    padding: 28px;
    border-radius: 8px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.14);
`;

export const Formated = styled.span`
    font-weight: 500;
    margin-bottom: 12px;
    color: #4e4e4e;
`;

export const AnswersBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Span = styled.span`
    margin-bottom: 8px;

    ${props =>
        props.answer &&
        css`
            color: #2173ef;
        `}
`;

export const Captions = styled.div`
    display: flex;
    align-items: center;
    margin: 30px 0;
    margin-left: 30px;

    span {
        margin-right: 12px;
    }
`;

export const Square = styled.div`
    height: 20px;
    width: 20px;
    background-color: black;
    margin-right: 8px;
    ${props =>
        props.color &&
        css`
            background-color: ${props.color};
        `}
`;
