import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { createSelector } from "reselect";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  evaluations: ["company_slug", "month", "year", "nps"],
  evaluationsSuccess: ["evaluations"],
  evaluationsFail: ["error"],
  clear: []
});

export const EvaluationTypes = Types;
export const EvaluationActions = Creators;

/* Selectors */

const selectLoading = createSelector(
  state => state.evaluation,
  evaluation => evaluation.loading
);

const selectEvaluations = createSelector(
  state => state.evaluation,
  evaluation => evaluation.evaluations
);

const selectBadEvaluations = createSelector(selectEvaluations, evaluations =>
  evaluations.filter(e => e.average >= 1 && e.average < 3)
);

const selectNeutralEvaluations = createSelector(
  selectEvaluations,
  evaluations => evaluations.filter(e => e.average >= 3 && e.average < 4)
);

const selectGoodEvaluations = createSelector(selectEvaluations, evaluations =>
  evaluations.filter(e => e.average >= 4 && e.average <= 5)
);

export const EvaluationSelectors = {
  selectLoading,
  selectEvaluations,
  selectBadEvaluations,
  selectNeutralEvaluations,
  selectGoodEvaluations
};

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  evaluations: [],
  error: null
});

/* Reducers */

export const evaluations = state => state.merge({ loading: true });

export const success = (state, { evaluations }) =>
  state.merge({ loading: false, evaluations });

export const fail = (state, { error }) =>
  state.merge({ loading: false, error });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.EVALUATIONS]: evaluations,
  [Types.EVALUATIONS_SUCCESS]: success,
  [Types.EVALUATIONS_FAIL]: fail
});
