import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { Colors, customMedia } from '../../themes';
import {
    OVERVIEW,
    DETALHES,
    AVALIACOES,
    SORTEIO,
    PERGUNTAS,
    AJUSTES,
    SUPORTE,
    SAIR,
} from './constants';

import OverviewSvg from '../../images/overview.svg';
import OverviewActiveSvg from '../../images/overview_active.svg';
import DetalhesSvg from '../../images/detalhes.svg';
import DetalhesActiveSvg from '../../images/detalhes_active.svg';
import AvaliacoesSvg from '../../images/avaliacoes.svg';
import AvaliacoesActiveSvg from '../../images/avaliacoes_active.svg';
import SorteioSvg from '../../images/sorteio.svg';
import SorteioActiveSvg from '../../images/sorteio_active.svg';
import PerguntasSvg from '../../images/perguntas.svg';
import PerguntasActiveSvg from '../../images/perguntas_active.svg';
import AjustesSvg from '../../images/ajustes.svg';
import AjustesActiveSvg from '../../images/ajustes_active.svg';
import SuporteSvg from '../../images/suporte.svg';
import SuporteActiveSvg from '../../images/suporte_active.svg';
import SairSvg from '../../images/sair.svg';
import SairActiveSvg from '../../images/sair_active.svg';

const getSvgIcon = ({ icon }, hover) => {
    if (icon === OVERVIEW) {
        if (hover) {
            return OverviewActiveSvg;
        }
        return OverviewSvg;
    }

    if (icon === DETALHES) {
        if (hover) {
            return DetalhesActiveSvg;
        }
        return DetalhesSvg;
    }

    if (icon === AVALIACOES) {
        if (hover) {
            return AvaliacoesActiveSvg;
        }
        return AvaliacoesSvg;
    }

    if (icon === SORTEIO) {
        if (hover) {
            return SorteioActiveSvg;
        }
        return SorteioSvg;
    }

    if (icon === PERGUNTAS) {
        if (hover) {
            return PerguntasActiveSvg;
        }
        return PerguntasSvg;
    }

    if (icon === AJUSTES) {
        if (hover) {
            return AjustesActiveSvg;
        }
        return AjustesSvg;
    }

    if (icon === SUPORTE) {
        if (hover) {
            return SuporteActiveSvg;
        }
        return SuporteSvg;
    }

    if (icon === SAIR) {
        if (hover) {
            return SairActiveSvg;
        }
        return SairSvg;
    }

    return null;
};

export const ToggleButton = styled(AntButton)`
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;

    ${customMedia.lessThan('desktop')`
    display: block;
  `}
`;

export const SidebarWrapper = styled.aside`
    background: #ffffff;
    box-shadow: inset -1px 0 0 0 ${Colors.border};
    min-width: 220px;
    height: 100%;
    transition: all 300ms ease-out;

    ${customMedia.lessThan('desktop')`
    position: absolute;
    min-width: 100vw;
    left: ${props => (props.toggled ? '0' : '-100%')};
  `}
`;

export const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled.h2`
    font-family: Gilroy-Bold;
    font-size: 14px;
    color: ${Colors.section_title};
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 20px;
`;

export const SectionOption = styled.a`
    display: flex;
    align-items: center;
    position: relative;
    font-family: Gilroy-Bold;
    font-size: 16px;
    color: ${props => (props.active ? Colors.blue : Colors.section_option)};
    letter-spacing: 0.11px;
    padding: 0 20px;
    height: 32px;
    margin-bottom: 20px;
    padding-top: 2px;
    text-transform: capitalize;
    cursor: pointer;
    transition: all 0.2s ease;

    ${customMedia.lessThan('desktop')`
    // flex-direction: row-reverse;
  `}

    ::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        background-color: ${Colors.blue};
        height: 100%;
        width: ${props => (props.active ? 3 : 0)}px;
        border-radius: 3px 0 0 3px;
    }

    & > img {
        width: 20px;
        height: 25px;
        margin-right: 20px;
        padding-bottom: 4px;

        content: url(${props => getSvgIcon(props, props.active)});
    }

    & > svg {
        margin-right: 20px;
        padding-bottom: 4px;
    }

    &:hover {
        color: ${Colors.blue};

        & > img {
            content: url(${props => getSvgIcon(props, true)});
        }
    }
`;
