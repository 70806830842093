import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

import { addHours, format } from 'date-fns';
import Api from '../../services/Api';
import * as S from './styles';
import InfiniteScroll from 'react-infinite-scroller';

const api = Api.create();

const Respostas = ({ company, token }) => {
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [infiniteLoading, setInfiniteLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(2);
    const [hasMore, setHasMore] = useState(true);
    const [allItems] = useState({});

    const fetchConversation = useCallback(async () => {
        if (currentPage !== 2) return true;
        try {
            const { data } = await api.getConversations(company, 'salao', token, 1);
            const filteredData = data.items.filter(({ answers }) => !_.isEmpty(answers));
            setConversations(filteredData);
            allItems[1] = filteredData;
            setLoading(false);
        } catch (e) {}
    }, [company, token, currentPage, allItems]);

    useEffect(() => {
        fetchConversation();
    }, [fetchConversation]);

    const loadMoreConversations = async () => {
        if (loading) return true;
        if (infiniteLoading) return true;
        if (allItems[1] && !allItems[1].length) return true;

        setInfiniteLoading(true);
        setCurrentPage(currentPage + 1);

        const { data } = await api.getConversations(company, 'salao', token, currentPage);

        if (currentPage >= data.pages) setHasMore(false);

        const filteredData = data.items.filter(({ answers }) => !_.isEmpty(answers));

        allItems[currentPage] = filteredData;

        let results = [];

        Object.keys(allItems).forEach(page => {
            results = [...results, ...allItems[page]];
        });

        setConversations([...results]);

        setInfiniteLoading(false);
    };

    if (!company)
        return (
            <S.Container>
                <h1 style={{ color: '#2173ef' }}>Escolha um formulário para ver as respostas</h1>
            </S.Container>
        );

    if (loading)
        return (
            <S.Container>
                <h1 style={{ color: '#2173ef', marginLeft: 30 }}>Carregando...</h1>
            </S.Container>
        );

    return (
        <S.Container>
            <h1 style={{ marginLeft: 12, textTransform: 'capitalize' }}>{company}</h1>
            <S.Captions>
                <S.Square />
                <span>Perguntas</span>
                <S.Square color="#2173ef" />
                <span>Respostas</span>
                <span>{conversations.length}</span>
            </S.Captions>
            <InfiniteScroll
                pageStart={1}
                loadMore={loadMoreConversations}
                hasMore={hasMore}
                loader={
                    <div className="loader" key={'infiniteLoader'}>
                        {infiniteLoading && <span>Carregando...</span>}
                    </div>
                }
                useWindow={false}
            >
                <S.Grid>
                    {conversations.map(item => {
                        const splitDate = item.date.split(' ');
                        const parsedDate = new Date(`${splitDate[0]}T${splitDate[1]}Z`);
                        const formatedDate = format(addHours(parsedDate, 3), 'dd/MM/yyyy HH:mm');

                        return (
                            <S.Tile key={`item-${item.id}`} cols={1}>
                                <span style={{ fontWeight: 600, color: '#4e4e4e' }}>
                                    {formatedDate}
                                </span>
                                {item.answers.map((answer, idx) => {
                                    const marginTop = idx === 0 ? 20 : 0;
                                    const array = answer.question.split('\n');
                                    return (
                                        <S.AnswersBlock
                                            key={`answer-${item.id}-${idx}`}
                                            style={{ marginTop }}
                                        >
                                            {array.map((x, itemID) => (
                                                <S.Span key={`answer$-${item.id}-${idx}-${itemID}`}>
                                                    {' '}
                                                    -- {x}
                                                </S.Span>
                                            ))}
                                            <S.Span answer> -- {answer.answer}</S.Span>
                                        </S.AnswersBlock>
                                    );
                                })}
                            </S.Tile>
                        );
                    })}
                </S.Grid>
            </InfiniteScroll>
        </S.Container>
    );
};

export default Respostas;
