import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { setHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPickersToolbarButton-toolbarBtn': {
            margin: 0,
        },
        '& .MuiFormHelperText-root': {
            color: 'red !important',
            fontSize: '1em',
            marginRight: 0,
            marginLeft: 0,
            // marginLeft: 0,
            '&.Mui-error': {
                color: theme.palette.error.main,
            },
        },
    },
}));

const DatePicker = ({ selectedDate, setSelectedDate, label }) => {
    const classes = useStyles();
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <KeyboardDatePicker
                disableToolbar
                margin="normal"
                id="date-picker-inline"
                label={label}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                autoOk
                disableFuture
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={selectedDate}
                invalidDateMessage="Data inválida."
                InputAdornmentProps={{ position: 'start' }}
                onChange={date => setSelectedDate(setHours(date, 12))}
                PopoverProps={{
                    className: classes.root,
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;
