/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { Container, TextContainer, ButtonsContainer, Button } from './styles';

const ChatBubble = ({ text, time, nps, current, close }) => {
    const [selected, setSelected] = useState(false);

    return (
        <Container>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '100%',
                }}
            >
                <div className="close" />
                <TextContainer selected={selected} cur={!!current} onClick={() => nps(selected)}>
                    <span className="text">{text}</span>
                    <span className="time">{time}</span>
                </TextContainer>
            </div>
            <ButtonsContainer>
                <Button
                    onClick={() => {
                        setSelected(!selected);
                        if (selected) {
                            close();
                        } else {
                            nps(true);
                        }
                    }}
                    selected={selected}
                >
                    <span>Resposta</span>
                </Button>
            </ButtonsContainer>
        </Container>
    );
};

export default ChatBubble;
