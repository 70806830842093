import styled, { css } from 'styled-components';
import { shade } from 'polished';
// import { customMedia } from '../../themes/global-styles';

export const ContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 80px);
`;

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

export const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 48%;
    background-color: #f1f2f3;
    border-radius: 12px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        height: 80px;
        width: 100%;

        .user-container {
            display: flex;
            align-items: center;

            & > span {
                font-size: 18px;
            }
        }

        .user {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-right: 12px;
        }

        .clear {
            display: flex;
            height: 44px;
            width: 44px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #ea192b;
            cursor: pointer;
            transition: background-color 0.4s;

            &:hover {
                background-color: ${shade(0.2, '#ea192b')};
            }
        }
    }

    .chat-content {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        height: 460px;
        width: 100%;
        align-items: flex-end;
        background-color: #e3e4e9;
        overflow-y: auto;
        overflow-x: hidden;

        .fix {
            flex: 1 1 auto;
        }
    }

    .write-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 152px;
        width: 100%;

        textarea {
            margin-left: 20px;
            width: 100%;
            resize: none;
            border: 1px solid #2173ef;
            border-radius: 8px;
            padding: 12px 14px;
        }

        div {
            display: flex;
            height: 50px;
            width: 60px;
            margin-left: 20px;
            margin-right: 24px;
            border-radius: 50%;
            background-color: #2173ef;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: ${shade(0.2, '#2173ef')};
            }

            svg {
                margin-top: 3px;
                margin-right: 3px;
                color: #fff;
            }
        }
    }
`;

export const NPSContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 48%;
    border-radius: 12px;
    background-color: #f1f2f3;
    margin-left: 40px;

    .content {
        display: flex;
        flex: 1;
    }
`;

export const ButtonsContainer = styled.div`
    margin-top: 8px;
    margin-bottom: 0px;
    display: flex;
    align-self: flex-end;

    .close {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2173ef;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding: 4px;
        transition: background-color 0.4s;
        margin-left: 80px;
        margin-top: 2px;

        &:hover {
            background-color: ${shade(0.2, '#2173ef')};
            cursor: pointer;
        }

        svg {
            color: white;
        }
    }
`;

export const Button = styled.div`
    display: flex;
    height: 40px;
    width: 100px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.4s;

    &:hover {
        background-color: ${shade(0.1, '#fff')};
    }

    & + div {
        margin-right: 12px;
    }

    span {
        color: #2173ef;
        font-size: 16px;
    }

    ${props =>
        props.selected &&
        css`
            background-color: #2173ef;

            &:hover {
                background-color: ${shade(0.2, '#2173ef')};
            }

            span {
                color: #fff;
            }
        `}
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;

    .fill {
        display: flex;
        flex: 1;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    p {
        font-size: 18px;
        font-weight: 600;
        margin-left: 12px;
    }

    p + p {
        font-size: 16px;
        color: #a2a8b0;
        font-weight: normal;
    }
`;

export const DropBox = styled.div`
    height: ${props => props.height};
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 12px 0 12px 12px;
    margin-top: 20px;
    border-radius: 6px;
    overflow: hidden;

    img {
        cursor: pointer;
        height: 20px;
        width: 20px;
        margin: 10px 20px 0 0;
        ${props =>
            props.expanded &&
            css`
                transform: rotate(180deg);
            `}
    }
`;

export const Red = styled.div`
    height: 40px;
    width: 32px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    border-radius: 6px;

    span {
        color: #fff;
    }
`;

export const Yellow = styled.div`
    height: 40px;
    width: 32px;
    background-color: #f1a718;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    border-radius: 6px;

    span {
        color: #fff;
    }
`;

export const Green = styled.div`
    height: 40px;
    width: 32px;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    border-radius: 6px;

    span {
        color: #fff;
    }
`;

export const SaveButton = styled.button`
    height: 58px;
    border-color: #2173ef;
    border-radius: 6px;
    cursor: pointer;

    span {
        color: #2173ef;
    }
`;

export const TextArea = styled.div`
    display: flex;
    align-items: center;
    height: 152px;
    width: 100%;

    textarea {
        margin-left: 6px;
        resize: none;
        border: 1px solid #2173ef;
        border-radius: 8px;
        padding: 12px 14px;
    }
`;

export const TextAreaNPS = styled.div`
    display: flex;
    align-items: center;
    height: 152px;
    width: 100%;
    ${props =>
        !props.expanded &&
        css`
            display: none;
        `}

    textarea {
        margin-left: 6px;
        resize: none;
        border: 1px solid #2173ef;
        border-radius: 8px;
        padding: 12px 14px;
    }
`;

export const SaveBox = styled.div`
    display: flex;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #3af4b022;
    border-radius: 6px;
    margin-bottom: 14px;

    span {
        font-size: 18px;
        font-weight: 600;
        color: #3af4b0;
        margin-left: 20px;
    }
`;

export const StarsContainer = styled.div`
    display: flex;
    height: 80px;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;
