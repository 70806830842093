import React from 'react';
import { Switch } from 'react-router-dom';

import LoginPage from '../containers/Login';
import HomePage from '../containers/Home';

import Route from './Route';

const Routes = () => (
    <Switch>
        <Route path="/" exact component={LoginPage} />
        <Route path="/home" exact component={HomePage} isPrivate />
    </Switch>
);

export default Routes;
