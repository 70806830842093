import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { createSelector } from "reselect";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  dashboard: ["slug", "month"],
  dashboardSuccess: [
    "best_day",
    "worst_day",
    "total_evaluations",
    "overall_average",
    "averages_by_category",
    "averages_by_month",
    "nps",
    "nps_months"
  ],
  dashboardFail: ["error"],
  setCompany: ["slug", "name"],
  clear: []
});

export const CompanyTypes = Types;
export const CompanyActions = Creators;

/* Selectors */

const selectLoading = createSelector(
  state => state.company,
  company => company.loading
);

const selectSlug = createSelector(
  state => state.company,
  company => company.slug
);

const selectName = createSelector(
  state => state.company,
  company => company.name
);

const selectBestDay = createSelector(
  state => state.company,
  company => company.best_day
);

const selectWorstDay = createSelector(
  state => state.company,
  company => company.worst_day
);

const selectTotalEvaluations = createSelector(
  state => state.company,
  company => company.total_evaluations
);

const selectOverallAverage = createSelector(
  state => state.company,
  company => company.overall_average
);

const selectAveragesByCategory = createSelector(
  state => state.company,
  company => company.averages_by_category
);

const selectAveragesByMonths = createSelector(
  state => state.company,
  company => company.averages_by_month
);

const selectNps = createSelector(
  state => state.company,
  company => company.nps
);
const selectNpsMonths = createSelector(
  state => state.company,
  company => company.nps_months
);

export const CompanySelectors = {
  selectSlug,
  selectName,
  selectLoading,
  selectBestDay,
  selectWorstDay,
  selectTotalEvaluations,
  selectOverallAverage,
  selectAveragesByCategory,
  selectAveragesByMonths,
  selectNps,
  selectNpsMonths
};

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  slug: null,
  name: null,
  nps: {},
  best_day: { date: null, value: 0 },
  worst_day: { date: null, value: 0 },
  total_evaluations: 0,
  overall_average: 0,
  averages_by_category: {},
  averages_by_month: {
    "1": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "2": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  },
  nps_months: {
    "1": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "2": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }
});

/* Reducers */

export const set = (state, { slug, name }) => state.merge({ slug, name });
export const dashboard = state => state.merge({ loading: true });
export const dashboardSuccess = (
  state,
  {
    best_day,
    worst_day,
    total_evaluations,
    overall_average,
    averages_by_category,
    averages_by_month,
    nps,
    nps_months
  }
) =>
  state.merge({
    loading: false,
    best_day,
    worst_day,
    total_evaluations,
    overall_average,
    averages_by_category,
    averages_by_month,
    nps,
    nps_months
  });
export const dashboardFail = state => state.merge({ loading: false });

export const clear = state => state.merge({ loading: false });

/* Reducers to types */

export default createReducer(INITIAL_STATE, {
  [Types.SET_COMPANY]: set,
  [Types.CLEAR]: clear,
  [Types.DASHBOARD]: dashboard,
  [Types.DASHBOARD_SUCCESS]: dashboardSuccess,
  [Types.DASHBOARD_FAIL]: dashboardFail
});
