import apisauce from 'apisauce';

const create = (baseURL = 'https://api.falacliente.com') => {
    const api = apisauce.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });

    const auth = async (username, password) => {
        const response = await api.post('/auth', { username, password });
        return response;
    };

    const getUser = async token => {
        const response = await api.get(
            '/account/',
            {},
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            },
        );
        return response;
    };

    const getCompany = async slug => {
        const response = await api.get(`/company/${slug}/salao`);
        return response;
    };

    const postChat = async (chat, token) => {
        const response = await api.post('/chat/flow', chat, {
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response;
    };

    const getConversations = async (a = 'teste', b, token, page = 0) => {
        const response = await api.get(
            `/chat/?company=${a}&place=${b}&page_size=50&page=${page}`,
            {},
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            },
        );
        return response;
    };

    const download = async (a = 'teste', b, token) => {
        const response = await api.get(
            `/chat/download?company=${a}&place=${b}`,
            {},
            {
                headers: {
                    'Content-Type': 'text/csv',
                    Authorization: `Token ${token}`,
                },
            },
        );
        return response;
    };

    const getAnswersData = async (company, token, start_date = '', end_date = '') => {
        const response = await api.get(
            `/chat/answers?company=${company}&start_date=${start_date}&end_date=${end_date}`,
            {},
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            },
        );
        return response;
    };

    return {
        auth,
        getUser,
        getCompany,
        postChat,
        getConversations,
        download,
        getAnswersData,
    };
};

export default {
    create,
};
