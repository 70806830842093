import React from 'react';
import { useDispatch } from 'react-redux';
import { FiHome } from 'react-icons/fi';
import * as S from './styles';

import { SAIR } from './constants';
import { AuthActions } from '../../redux/ducks/AuthRedux';

const Sidebar = ({ active, setActive }) => {
    const dispatch = useDispatch();

    const getOption = (tag, name) => {
        return (
            <S.SectionOption
                active={tag === active}
                icon={tag}
                onClick={() => {
                    if (name === 'Sair') return dispatch(AuthActions.logout());
                    return setActive(tag);
                }}
            >
                {tag === 'home' ? (
                    <>
                        <FiHome size={24} /> {tag}
                    </>
                ) : (
                    <>
                        <img alt={tag} /> {name || tag}
                    </>
                )}
            </S.SectionOption>
        );
    };

    return (
        <>
            <S.SidebarWrapper>
                <S.SectionWrapper>
                    <S.SectionTitle>Principais</S.SectionTitle>
                    {getOption('home', 'Home')}
                    {getOption('overview', 'Respostas')}
                    {getOption('detalhes', 'Dashboard')}
                    {/* getOption(OVERVIEW) */}
                    {/* getOption(AVALIACOES, 'Avaliações') */}
                    {/* getOption(SORTEIO) */}
                    {/* getOption(PERGUNTAS) */}
                </S.SectionWrapper>

                <S.SectionWrapper>
                    <S.SectionTitle>Configurações</S.SectionTitle>
                    {/* getOption(AJUSTES) */}
                    {/* getOption(SUPORTE) */}
                    {getOption(SAIR, 'Sair')}
                </S.SectionWrapper>
            </S.SidebarWrapper>
        </>
    );
};

export default Sidebar;
